import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Maybe, SupplyTypeDetail } from '../../../__generated__/pge-types';
import { RENEWABLE_TYPES, SUPPLY_COLOR_CODES } from '../supply.const';
import DonutChart, { DonutChartDataPoint } from '../DonutChart';
import { useTranslation } from '../../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      fontSize: '1.7rem',
    },
  }),
);

export const generateData = (props: CurrentRenewablesProps) => {
  const data: DonutChartDataPoint[] = [];

  props?.data
    ?.filter(supply => RENEWABLE_TYPES.includes(supply?.type!))
    .map(NRSupply =>
      data.push({
        name: NRSupply?.type.toLowerCase()!,
        value: parseFloat(NRSupply?.supply?.toFixed(2)!),
        fill: SUPPLY_COLOR_CODES[NRSupply?.type.toLowerCase()!],
      }),
    );

  return data;
};

interface CurrentRenewablesProps {
  data: Maybe<SupplyTypeDetail>[];
}

const CurrentRenewables: FC<CurrentRenewablesProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = generateData(props);

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Typography className={classes.titleText} gutterBottom>
        {t('POWER_PORTFOLIO_CURRENT_RENEWABLES')}
      </Typography>
      <Card variant="elevation">
        <CardContent>
          <DonutChart data={data} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CurrentRenewables;
