import React, { useState } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  YAxis,
  Tooltip,
  Legend,
  XAxis,
  Line,
  LegendProps,
  Surface,
} from 'recharts';
import CustomTooltip from './CustomTooltip';
import { dateFormatter, getColorCode } from './Utils';
import _includes from 'lodash/includes';
import { toSentenceCase } from '../../util/format';
import { useIsMobile } from '../../util/style-utils';

const SIZE = 32;

interface Props {
  dataPoints: any;
}

const LineChartComponent = ({ dataPoints }: Props) => {
  const [disabledKeys, setDisabledKeys] = useState<string[]>([]);

  const isMobile = useIsMobile();

  const handleLegendItemClick = (dataKey: string) => {
    if (_includes(disabledKeys, dataKey)) {
      setDisabledKeys(disabledKeys.filter(obj => obj !== dataKey));
    } else {
      setDisabledKeys([...disabledKeys, dataKey]);
    }
  };

  const renderCustomizedLegend = ({ payload }: LegendProps) => {
    const halfSize = SIZE / 2;
    const sixthSize = SIZE / 6;
    const thirdSize = SIZE / 3;
    const viewBox = { x: 0, y: 0, width: SIZE, height: SIZE };

    const svgStyle = {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 4,
    };

    return (
      <div
        className="customized-legend"
        style={{ textAlign: 'center', marginTop: '15px', paddingLeft: '65px' }}
      >
        {payload &&
          payload.map(entry => {
            //@ts-ignore
            const { dataKey, color } = entry;
            const active = _includes(disabledKeys, dataKey);
            const style = {
              marginRight: 10,
              color: active ? '#AAA' : '#000',
              cursor: 'pointer',
            };

            return (
              <span
                className="legend-item"
                onClick={() => handleLegendItemClick(dataKey)}
                style={style}
              >
                <Surface
                  width={22}
                  height={22}
                  viewBox={viewBox}
                  style={svgStyle}
                >
                  <path
                    strokeWidth={4}
                    fill="none"
                    stroke={color}
                    opacity={active ? 0.4 : 1}
                    d={`M0,${halfSize}h${thirdSize}
                                    A${sixthSize},${sixthSize},0,1,1,${2 *
                      thirdSize},${halfSize}
                                    H${SIZE}M${2 * thirdSize},${halfSize}
                                    A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
                    className="recharts-legend-icon"
                  />
                </Surface>

                <span>{toSentenceCase(dataKey)}</span>
              </span>
            );
          })}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={850}
        height={300}
        data={dataPoints}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {dataPoints &&
          Object.keys(dataPoints[0])
            .filter(i => i !== 'time')
            .filter(pair => !_includes(disabledKeys, pair))
            .map(key => (
              <Line
                type="linear"
                dataKey={key}
                key={key}
                stroke={getColorCode(key)}
                strokeWidth={2}
                dot={false}
              />
            ))}

        <CartesianGrid stroke="#ccc" vertical={false} />
        <XAxis
          dataKey={'time'}
          tickFormatter={time => dateFormatter(time, true)}
          domain={[
            dateFormatter(dataPoints[0].time),
            dateFormatter(dataPoints[1].time),
          ]}
          interval={isMobile ? 7 : 1}
          label={{ value: 'Time', position: 'insideBottom', offset: -5 }}
        />
        <YAxis label={{ value: 'MW', angle: -90, position: 'insideLeft' }} />
        <Tooltip
          active={true}
          labelFormatter={dateFormatter}
          content={<CustomTooltip />}
        />
        <Legend
          payload={Object.keys(dataPoints[0])
            .filter(i => i !== 'time')
            .map(key => ({
              dataKey: key,
              color: getColorCode(key),
              value: key,
              id: key,
              type: 'line',
            }))}
          content={renderCustomizedLegend}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
