import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EcoIcon from '@material-ui/icons/Eco';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import StatisticsCard from './StatisticsCard';
import {
  SupplyTypeDetail,
  Maybe,
  SupplyType,
} from '../../../__generated__/pge-types';
import {
  RENEWABLE_TYPES,
  SUPPLY_COLOR_CODES,
  SUPPLY_TYPES,
} from '../supply.const';
import TemperatureIcon from '../../../static/temperature.svg';
import WindIcon from '../../../static/wind.svg';
import { useTranslation } from '../../../hooks/useTranslation';

interface IProps {
  data: Maybe<SupplyTypeDetail>[];
  temperature: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBottom: {
      marginBottom: '40px',
    },
  }),
);

const SupplyStatistics: FC<IProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const CurrentDemand = props?.data?.reduce((a, b) => a + (b?.supply || 0), 0);
  const CurrentRenewables = props?.data
    ?.filter(supply => RENEWABLE_TYPES.includes(supply?.type!))
    .reduce((a, b) => a + (b?.supply || 0), 0);
  const CurrentSolar = props?.data?.find(
    supply => supply?.type === SupplyType.Solar,
  )?.supply;
  const CurrentWind = props?.data?.find(
    supply => supply?.type === SupplyType.Wind,
  )?.supply;

  return (
    <Grid
      spacing={3}
      item
      container
      direction="row"
      justify="space-between"
      className={classes.marginBottom}
    >
      <StatisticsCard
        icon={
          <EmojiObjectsIcon
            height={32}
            style={{ color: '#35bdb2', fontSize: 30 }}
          />
        }
        label={t('POWER_PORTFOLIO_CURRENT_DEMAND')}
        value={`${CurrentDemand?.toFixed(2)} MW`}
      />

      <StatisticsCard
        icon={
          <EcoIcon
            height={32}
            style={{ color: SUPPLY_COLOR_CODES.renewables, fontSize: 30 }}
          />
        }
        label={t('POWER_PORTFOLIO_CURRENT_RENEWABLES')}
        value={`${CurrentRenewables?.toFixed(2)} MW`}
      />

      <StatisticsCard
        icon={
          <WbSunnyIcon
            height={32}
            style={{
              color: SUPPLY_COLOR_CODES[SUPPLY_TYPES.SOLAR],
              fontSize: 30,
            }}
          />
        }
        label={t('POWER_PORTFOLIO_CURRENT_SOLAR')}
        value={`${CurrentSolar?.toFixed(2)} MW`}
      />

      <StatisticsCard
        icon={
          <WindIcon
            style={{
              height: '32px',
              width: '32px',
            }}
          />
        }
        label={t('POWER_PORTFOLIO_CURRENT_WIND')}
        value={`${CurrentWind?.toFixed(2)} MW`}
      />

      <StatisticsCard
        icon={<TemperatureIcon style={{ height: '32px' }} />}
        label={t('POWER_PORTFOLIO_CURRENT_TEMPERATURE')}
        value={`${props.temperature?.toFixed(2)} °F`}
      />
    </Grid>
  );
};

export default SupplyStatistics;
