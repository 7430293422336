import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import moment from 'moment';
import { RENEWABLE_TYPES } from '../supply.const';
import {
  Maybe,
  SupplyHistory,
  Scalars,
  SupplyType,
} from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import LineChartComponent from '../LineChart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    supplyTrendContainer: {
      height: 400,
      width: '100%',
      '& .recharts-legend-item-text': {
        fontSize: '1rem',
      },
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    emptyplaceholder: {
      textAlign: 'center',
      paddingTop: '8rem',
      fontSize: '1.5rem',
    },
    titleText: {
      fontSize: '1.7rem',
    },
  }),
);

interface SupplyTrendProps {
  data: Maybe<SupplyHistory>[];
}

interface RenewablesTrendDataPoints {
  time: Scalars['DateTime'];
}

export const generateDataPoints = (supplyHistory: Maybe<SupplyHistory>[]) => {
  if (!supplyHistory || supplyHistory.length === 0) {
    return [];
  }

  const collection: RenewablesTrendDataPoints[] = [];

  supplyHistory.forEach(item => {
    const dataItem: any = [];

    //Calculate the renewable values
    const renewableSources = item?.supplyTypeDetails?.filter(supply =>
      RENEWABLE_TYPES.includes(supply?.type!),
    );

    renewableSources?.forEach(renewable => {
      dataItem[renewable?.type.toLowerCase()!] = renewable?.supply;
    });

    dataItem['time'] = item?.intervalDateTime;
    collection.push({ ...dataItem });
  });

  //Fill the remaining hours of the day
  if (collection.length < 48 && collection.length > 0) {
    for (let i = collection.length; i < 48; i++) {
      const lastDateTime = collection[collection.length - 1]['time'];
      collection.push({
        time: moment(lastDateTime)
          .add(30, 'minutes')
          .toISOString(),
      });
    }
  }

  return collection;
};

const RenewablesTrend: FC<SupplyTrendProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = generateDataPoints(props?.data);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box>
        <Typography className={classes.titleText} gutterBottom>
          {t('POWER_PORTFOLIO_RENEWABLES_TREND')}
        </Typography>
      </Box>
      <Card variant="elevation">
        <CardContent className={classes.supplyTrendContainer}>
          {props.data && props.data.length > 0 && (
            <Box
              data-testid="renewables-chart-container"
              style={{ width: '100%', height: '100%' }}
            >
              <LineChartComponent dataPoints={data} />
            </Box>
          )}

          {(!props.data || props.data.length === 0) && (
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.emptyplaceholder}
              >
                {t('POWER_PORTFOLIO_NO_DATA_AVAILABLE')}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RenewablesTrend;
