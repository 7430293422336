import React, { FC, useContext, useState } from 'react';
import { Container, Divider, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CurrentRenewables,
  CurrentSupply,
  SupplyStatistics,
  SupplyTrend,
} from '../../components/supply-and-renewables';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '../../__generated__/pge-types';
import {
  getPowerPortfolio,
  getSupplyHistory,
} from '../../queries/supplyAndRenewables.query';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { useTranslation } from '../../hooks/useTranslation';
import moment from 'moment';
import Backdrop from '../backdrop';
import RenewablesTrend from './renewables-trend/RenewablesTrend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(25),
      '& .recharts-legend-item': {
        marginBottom: '5px !important',
      },
      '& .recharts-legend-item-text': {
        textTransform: 'capitalize !important',
      },
    },
    dividerMargin: {
      margin: '0.5rem 0 1rem 0',
    },
    titleText: {
      fontSize: '1.7rem',
    },
    smallFont: {
      fontSize: '1rem',
    },
  }),
);

export interface Props {
  path: string;
}

const SupplyAndRenewablesPage: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationsContext);
  const [selectedDate, setSelectedDate] = useState<string>(
    moment('08/30/2021').format('MM/DD/YYYY'),
  );
  const [error, setError] = useState<boolean>(false);

  const spinnerText = t('POWER_PORTFOLIO_LOADING_DATA');

  const setErrorNotification = (isError: boolean) => {
    notificationContext.setState({
      isOpen: isError,
      message: isError
        ? t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY')
        : undefined,
      severity: isError ? 'error' : undefined,
    });
    isError && typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  const { data: SupplyData, loading } = useQuery<Query>(getPowerPortfolio, {
    fetchPolicy: 'network-only',
    onError: () => {
      setErrorNotification(true);
      setError(true);
    },
  });

  const { data: SupplyHistory, loading: supplyHistoryLoading } = useQuery<
    Query
  >(getSupplyHistory, {
    variables: {
      supplyHistorySupplyHistoryParams: {
        supplyDate: moment(selectedDate).toISOString(),
        interval: 30,
      },
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setErrorNotification(true);
      setError(true);
    },
  });

  const dateFormatter = (date: Date) =>
    moment(date).format('MM/DD/YYYY h:mm A');

  if (loading) {
    return <Backdrop forceOpen message={spinnerText} />;
  }

  if (error) {
    return null;
  }

  return (
    <Container className={classes.main}>
      <Typography className={classes.titleText} gutterBottom display="inline">
        {t('POWER_PORTFOLIO_PAGE_TITLE')}{' '}
        <Typography
          variant="caption"
          display="inline"
          gutterBottom
          className={classes.smallFont}
        >
          {dateFormatter(SupplyData?.getPowerPortfolio?.lastUpdateTime)}
        </Typography>
      </Typography>

      <Divider className={classes.dividerMargin} />

      <SupplyStatistics
        data={SupplyData?.getPowerPortfolio?.supplyTypeDetails!}
        temperature={SupplyData?.getPowerPortfolio?.temp!}
      />

      <Grid container spacing={3}>
        <CurrentSupply
          data={SupplyData?.getPowerPortfolio?.supplyTypeDetails!}
          load={SupplyData?.getPowerPortfolio?.load!}
        />
        <CurrentRenewables
          data={SupplyData?.getPowerPortfolio?.supplyTypeDetails!}
        />
        <SupplyTrend
          data={SupplyHistory?.getPowerPortfolio?.supplyHistory!}
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
          loading={supplyHistoryLoading}
        />
        <RenewablesTrend
          data={SupplyHistory?.getPowerPortfolio?.supplyHistory!}
        />
      </Grid>
    </Container>
  );
};

export default SupplyAndRenewablesPage;
