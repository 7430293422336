import {
  Box,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { dateFormatter } from './Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipRoot: {
      padding: '0',
      margin: '0',
    },
    noPadding: {
      padding: '8px 0',
    },
    padding: {
      padding: '5px 16px 0px 16px',
    },
    TooltipTitle: {
      fontSize: 14,
      textAlign: 'center',
    },
    textSmall: {
      textTransform: 'capitalize',
      fontSize: '1rem',
    },
  }),
);

interface TooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Card className={classes.tooltipRoot}>
        <CardContent className={classes.noPadding}>
          <Typography
            className={classes.TooltipTitle}
            variant="h5"
            component="h2"
          >
            {dateFormatter(label)}
          </Typography>
          <hr />
          <Box className={classes.padding}>
            {payload.map((item: any) => (
              <small className={classes.textSmall} key={item.name}>
                <span style={{ color: item.color }}>●</span>{' '}
                <strong>{item.name.toLowerCase()}</strong> :{' '}
                {item?.value?.toFixed(2)} <br />
              </small>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default CustomTooltip;
