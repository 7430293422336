import React, { FC, useContext, useEffect } from 'react';
import { Router, navigate } from '@reach/router';

import SupplyAndRenewablesPage from '../../components/supply-and-renewables/SupplyAndRenewablesPage';
import routes from '../../routes';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';

const SupplyAndRenewables: FC = () => {
  const { isPowerPortfolioEnabled, loading } = useContext(featureFlagsContext);

  //Redirect to home page is power portfolio is not enabled
  useEffect(() => {
    async function navigateToHome() {
      await navigate(routes.HOME);
    }

    if (!loading && !isPowerPortfolioEnabled) {
      navigateToHome()
        .then(e => {
          console.log('Navigating to home page');
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [loading, isPowerPortfolioEnabled]);

  return (
    <Router basepath="/">
      <SupplyAndRenewablesPage path={routes.SUPPLY_AND_RENEWABLES} />
    </Router>
  );
};

export default SupplyAndRenewables;
