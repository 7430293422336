import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import moment from 'moment';
import DatePicker from '../../date-picker';
import PGEButton from '../../buttons';
import { RENEWABLE_TYPES } from '../supply.const';
import {
  Maybe,
  SupplyHistory,
  Scalars,
  SupplyType,
} from '../../../__generated__/pge-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from '../../../hooks/useTranslation';
import LineChartComponent from '../LineChart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-marginNormal': {
        marginTop: '0',
      },
      '& .MuiOutlinedInput-input': {
        padding: '7.5px 14px',
        fontSize: '1rem',
      },
      '& .MuiInputLabel-formControl': {
        top: '-10px',
      },
      '& .MuiIconButton-root': {
        padding: '0',
      },
      '& .custom-tooltip': {
        backgroundColor: '#DCDCDC',
        padding: '10px',
      },
      '& .MuiInputLabel-formControl.Mui-focused, .MuiInputLabel-formControl.MuiFormLabel-filled': {
        top: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
      },
      '& .recharts-legend-item-text': {
        fontSize: '1rem',
      },
    },
    supplyTrendContainer: {
      height: 400,
      width: '100%',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    marginLeft: {
      marginLeft: '10px',
    },
    emptyplaceholder: {
      textAlign: 'center',
      paddingTop: '8rem',
      fontSize: '1.5rem',
    },
    marginTop: {
      marginTop: '0px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '20px',
      },
    },
    titleText: {
      fontSize: '1.7rem',
    },
  }),
);

export const generateDataPoints = (supplyHistory: Maybe<SupplyHistory>[]) => {
  if (!supplyHistory || supplyHistory.length === 0) {
    return [];
  }

  const collection: SupplyTrendDataPoints[] = [];

  supplyHistory.forEach(item => {
    const dataItem: any = [];
    //Calculate the renewable values
    const renewableSources = item?.supplyTypeDetails?.filter(supply =>
      RENEWABLE_TYPES.includes(supply?.type!),
    );

    const nonRenewableSources = item?.supplyTypeDetails?.filter(
      supply => !RENEWABLE_TYPES.includes(supply?.type!),
    );

    const allSupply = item?.supplyTypeDetails
      ?.filter(supply => supply?.type! !== SupplyType.Baoload)
      .reduce((a, b) => a + (b?.supply || 0), 0);

    const baoLoad = item?.supplyTypeDetails?.filter(
      supply => supply?.type! === SupplyType.Baoload,
    );

    const ImportedSupply =
      baoLoad && allSupply && baoLoad[0]?.supply! - allSupply;

    dataItem['time'] = item?.intervalDateTime;
    if (renewableSources && renewableSources.length > 0) {
      dataItem['Renewables'] = renewableSources.reduce(
        (a, b) => a + (b?.supply || 0),
        0,
      );
    }

    dataItem['Imports'] =
      ImportedSupply && ImportedSupply < 0 ? 0 : ImportedSupply;

    nonRenewableSources?.forEach(nonRenewable => {
      dataItem[
        nonRenewable?.type! === SupplyType.Baoload
          ? 'All'
          : nonRenewable?.type.toLowerCase()!
      ] = nonRenewable?.supply;
    });

    collection.push({ ...dataItem });
  });

  //Fill the remaining hours of the day
  if (collection.length < 48 && collection.length > 0) {
    for (let i = collection.length; i < 48; i++) {
      const lastDateTime = collection[collection.length - 1]['time'];
      collection.push({
        time: moment(lastDateTime)
          .add(30, 'minutes')
          .toISOString(),
        Renewables: null,
      });
    }
  }

  return collection;
};

interface SupplyTrendProps {
  data: Maybe<SupplyHistory>[];
  onDateChange: Function;
  selectedDate: string;
  loading?: boolean;
}

interface SupplyTrendDataPoints {
  time: Scalars['DateTime'];
  Renewables: number | null;
}

const SupplyTrend: FC<SupplyTrendProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dataPoints = generateDataPoints(props?.data);

  const downloadCSV = () => {
    let csv = '';
    const items = [...dataPoints];
    items.unshift(dataPoints[0]);

    // Loop the array of objects
    for (let row = 0; row < items.length; row++) {
      const keysAmount = Object.keys(items[row]).length;
      let keysCounter = 0;

      // If this is the first row, generate the headings
      if (row === 0) {
        // Loop each property of the object
        for (const key in items[row]) {
          // This is to not add a comma at the last cell
          // The '\r\n' adds a new line
          csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
          keysCounter++;
        }
      } else {
        for (const key in items[row]) {
          csv +=
            //@ts-ignore
            items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
          keysCounter++;
        }
      }
      keysCounter = 0;
    }

    // Once we are done looping, download the .csv by creating a link
    const link = document.createElement('a');
    link.id = 'download-csv';
    link.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(csv),
    );
    link.setAttribute(
      'download',
      'supply-history-' +
        moment(props.selectedDate).format('MM-DD-YYYY') +
        '.csv',
    );
    document.body.appendChild(link);
    document.getElementById('download-csv')?.click();
    const elem = document.getElementById('download-csv');
    elem?.parentNode?.removeChild(elem);
  };

  const onDateChange = ({ target: { value } }: any) => {
    console.log('Date Value');
    const datePattern = /\d{2}\/\d{2}\/\d{4}/;
    if (datePattern.test(value) && typeof props.onDateChange === 'function') {
      props.onDateChange(value);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} className={classes.root}>
      <Grid container>
        <Grid item container direction="row" justify="space-between">
          <Typography className={classes.titleText} gutterBottom>
            {t('POWER_PORTFOLIO_SUPPLY_TREND')}
          </Typography>
          <Box className={classes.marginTop}>
            {props.loading && (
              <CircularProgress
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
            )}
            <DatePicker
              style={{ width: '160px' }}
              label={t('POWER_PORTFOLIO_DATE_LABEL')}
              name="selectedDate"
              onChange={(e: any) => onDateChange(e)}
              value={props.selectedDate}
              disableFuture={true}
              minDate={moment().subtract(6, 'months')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                ...({ ['data-testid']: 'changeDate' } as any),
              }}
            />
            <PGEButton
              color={'secondary'}
              variant={'outlined'}
              onClick={downloadCSV}
              className={classes.marginLeft}
              disabled={!props.data || props.data.length === 0}
              data-testid="download-as-csv"
            >
              {t('POWER_PORTFOLIO_DOWNLOAD_AS_CSV')}
            </PGEButton>
          </Box>
        </Grid>
      </Grid>

      <Card variant="elevation">
        <CardContent className={classes.supplyTrendContainer}>
          {props.data && props.data.length > 0 && (
            <Box
              data-testid="supply-trend-chart-container"
              style={{ width: '100%', height: '100%' }}
            >
              <LineChartComponent dataPoints={dataPoints} />
            </Box>
          )}

          {(!props.data || props.data.length === 0) && (
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.emptyplaceholder}
              >
                {t('POWER_PORTFOLIO_NO_DATA_AVAILABLE')}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SupplyTrend;
