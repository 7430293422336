import gql from 'not-graphql-tag';

export const getPowerPortfolio = gql`
  query getPowerPortfolio {
    getPowerPortfolio {
      lastUpdateTime
      load
      temp
      supplyTypeDetails {
        type
        supply
      }
    }
  }
`;

export const getSupplyHistory = gql`
  query Query($supplyHistorySupplyHistoryParams: SupplyHistoryParams!) {
    getPowerPortfolio {
      supplyHistory(supplyHistoryParams: $supplyHistorySupplyHistoryParams) {
        intervalDateTime
        supplyTypeDetails {
          supply
          type
        }
      }
    }
  }
`;
