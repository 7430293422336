import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useState } from 'react';
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import { useIsMobile } from '../../util/style-utils';
import { toSentenceCase } from '../../util/format';

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={2} textAnchor="middle" fill={fill}>
        {toSentenceCase(payload.name)}
      </text>
      <text x={cx} y={cy} dy={18} textAnchor="middle" fill={fill}>
        {value}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 3}
        outerRadius={outerRadius + 7}
        fill={fill}
      />
    </g>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      height: 400,
      width: '100%',
    },
    legendFontSize: {
      fontSize: '1rem',
    },
  }),
);

interface DonutChartProps {
  data: DonutChartDataPoint[];
}

export interface DonutChartDataPoint {
  name: string;
  value: number;
  fill: string;
}

const DonutChart: FC<DonutChartProps> = props => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  const isMobile = useIsMobile();

  return (
    <Box
      className={classes.chartContainer}
      onMouseLeave={() => setActiveIndex(undefined)}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={props.data}
            dataKey="value"
            innerRadius={isMobile ? 60 : 90}
            outerRadius={isMobile ? 90 : 130}
            fill="#8884d8"
            legendType="circle"
            onMouseEnter={(data, index) => setActiveIndex(index)}
          >
            {props?.data?.map((d, index) => (
              <Cell fill={d.fill} key={index} />
            ))}
          </Pie>
          {/* <Legend layout="vertical" verticalAlign="middle" align="right" /> */}

          <Legend
            height={isMobile ? 200 : 300}
            width={230}
            layout={isMobile ? 'horizontal' : 'vertical'}
            iconType="circle"
            align={isMobile ? 'left' : 'right'}
            wrapperStyle={{ margin: '10px 0px -25px 80px' }}
            payload={props?.data?.map(item => ({
              id: item.name,
              color: item.fill,
              type: 'circle',
              value: (
                <span>
                  <strong className={classes.legendFontSize}>
                    {item.name}
                  </strong>{' '}
                  <>({item.value} MW)</>
                </span>
              ),
            }))}
          />

          {/* <Tooltip /> */}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DonutChart;
