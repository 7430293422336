import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Maybe,
  SupplyType,
  SupplyTypeDetail,
} from '../../../__generated__/pge-types';
import {
  RENEWABLE_TYPES,
  SUPPLY_COLOR_CODES,
  SUPPLY_TYPES,
} from '../supply.const';
import DonutChart, { DonutChartDataPoint } from '../DonutChart';
import { useTranslation } from '../../../hooks/useTranslation';
import { generateDataPoints } from '../supply-trend/SupplyTrend';

interface CurrentSupplyProps {
  data: Maybe<SupplyTypeDetail>[];
  load: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      fontSize: '1.7rem',
    },
  }),
);

export const generateData = (
  props: CurrentSupplyProps,
  renewablesLabel = '',
) => {
  const data: DonutChartDataPoint[] = [];

  //Adding up all renewable sources
  const CurrentRenewables = props?.data
    ?.filter(supply => RENEWABLE_TYPES.includes(supply?.type!))
    .reduce((a, b) => a + (b?.supply || 0), 0);

  data.push({
    name: renewablesLabel,
    value: parseFloat(CurrentRenewables?.toFixed(2)),
    fill: SUPPLY_COLOR_CODES.renewables,
  });

  //Push the values received from the API
  props?.data
    ?.filter(supply => !RENEWABLE_TYPES.includes(supply?.type!))
    .map(NRSupply =>
      data.push({
        name: NRSupply?.type.toLocaleLowerCase()!,
        value: parseFloat(NRSupply?.supply?.toFixed(2)!),
        fill: SUPPLY_COLOR_CODES[NRSupply?.type.toLowerCase()!],
      }),
    );

  const allSupply = props?.data
    ?.filter(supply => supply?.type! !== SupplyType.Baoload)
    .reduce((a, b) => a + (b?.supply || 0), 0);

  //Calculating imported supply based on the load and other supplies
  const ImportedSupply: number =
    props?.load && allSupply && props?.load - allSupply;

  data.push({
    name: SUPPLY_TYPES.IMPORTS,
    value:
      ImportedSupply && ImportedSupply < 0
        ? 0
        : parseFloat(ImportedSupply?.toFixed(2)),
    fill: SUPPLY_COLOR_CODES[SUPPLY_TYPES.IMPORTS],
  });

  //Other extra supply types
  data.push({
    name: SUPPLY_TYPES.BATTERIES,
    value: 0,
    fill: SUPPLY_COLOR_CODES[SUPPLY_TYPES.BATTERIES],
  });

  data.push({
    name: SUPPLY_TYPES.OTHERS,
    value: 0,
    fill: SUPPLY_COLOR_CODES[SUPPLY_TYPES.OTHERS],
  });

  return data;
};

const CurrentSupply: FC<CurrentSupplyProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = generateData(props, t('POWER_PORTFOLIO_RENEWABLES'));

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Typography className={classes.titleText} gutterBottom>
        {t('POWER_PORTFOLIO_CURRENT_SUPPLY')}
      </Typography>
      <Card variant="elevation">
        <CardContent>
          <DonutChart data={data} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CurrentSupply;
