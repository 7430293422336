import React, { FC, ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

export interface IProps {
  label: string;
  value: string;
  icon: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statisticsCard: {
      padding: theme.spacing(5, 0),
      textAlign: 'center',
    },
  }),
);
const StatisticsCard = (props: IProps) => {
  const classes = useStyles();
  const { icon, label, value } = props;

  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card variant="elevation" className={classes.statisticsCard}>
        {icon}
        <Typography variant="h3">{value || ''}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {label}
        </Typography>
      </Card>
    </Grid>
  );
};

export default StatisticsCard;
