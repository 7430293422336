import moment from 'moment';
import { SUPPLY_COLOR_CODES } from './supply.const';

export const dateFormatter = (date: any, hourOnly: boolean = false) => {
  if (hourOnly) {
    return moment(date)
      .utc()
      .format('HH');
  }

  return moment(date)
    .utc()
    .format('HH:mm');
};

export const getColorCode = (type: string) => {
  return SUPPLY_COLOR_CODES[type.toLowerCase()] || '#222222';
};
