import { SupplyType } from '../../__generated__/pge-types';

export const SUPPLY_TYPES = {
  SOLAR: 'solar',
  WIND: 'wind',
  HYDRO: 'hydro',
  THERMAL: 'thermal',
  BATTERIES: 'batteries',
  OTHERS: 'others',
  IMPORTS: 'imports',
};

export const RENEWABLE_TYPES = [SupplyType.Wind, SupplyType.Solar];

export const SUPPLY_COLOR_CODES = {
  renewables: '#84bd00',
  [SUPPLY_TYPES.IMPORTS]: '#b93f1e',
  [SUPPLY_TYPES.WIND]: '#3b6e8f',
  [SUPPLY_TYPES.SOLAR]: '#ffa300',
  [SUPPLY_TYPES.HYDRO]: '#35bdb2',
  [SUPPLY_TYPES.THERMAL]: '#8f500d',
  [SUPPLY_TYPES.BATTERIES]: '#ffd597',
  [SUPPLY_TYPES.OTHERS]: '#9f97b7',
};
